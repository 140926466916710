.activeInfo {
  padding: 16px;
  background: #FFFFFF;
  margin-top: 20px;
  font-weight: 400;
  color: #333333;
  font-size: 14px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  margin-bottom: 10px;

  span {
    min-width: 200px;
    display: inline-block;
    margin-right: 50px;
    line-height: 1.8;
  }
}

.tableMember {
  margin-bottom: 16px;
}

.commonClass {
  padding: 16px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.06);
  border-radius: 4px;

  .title {
    border-bottom: 1px solid rgba(130, 182, 253, 0.5);
    padding: 0 0 12px 12px;
    margin-bottom: 14px;
    font-weight: bold;
    font-size: 16px;
  }
}

.desc {
  line-height: 2;
  font-size: 14px;
  color: #333333;
  text-align: justify;
  margin-bottom: 14px;
  padding-left: 12px;

  span {
    display: inline-block;
    min-width: 200px;
    margin-right: 10px;
  }

}

.aniSupply {
  font-size: 14px;
  color: #333333;
  margin-bottom: 18px;

  span {
    display: inline-block;
    width: 300px;
    margin-right: 20px;

    label {
      width: 86px;
      text-align: right;
      display: inline-block;
    }
  }
}
